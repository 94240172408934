




























































































































































































































































































































































































































import { CacheService, Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { dateFormat } from '@cds/common';
import { SurveyService } from '@/services/survey-service';
import filesDialog from '../../components/surveyUpload/surveyfiles.vue';
import filesDialog2 from '../../components/surveyUpload/surveyfiles2.vue';
import openDialog from '../../components/surveyUpload/openDialog.vue';


import moment from 'moment';

@Component({
  components: {
    filesDialog,
    filesDialog2,
    openDialog

  }
})
export default class editSurveyQuestionnaire extends Vue {
  public form: any = {
    // pages: 1,
    current: 1,
    size: 10,
    userEmail: '',
    userCode: '',
    total: 0

  };
  public queryParams: any = { //查询参数

    userPostName: '',
    pages: 1,
    size: 5,
    current: 1,
    total: 0
  };
  public formInfo: any = {
    'deadlineDay': null,
    'typeName': null,
    'timeType': null,
    'startDateTime': null,
    'endDateTime': null,
    'isCirculate': 0,
    'time': [],
    'releaseDateTime': '',
    'publishStatus': null,
    'surveyId': ''

  };
  public formInline: any = {
    'spmHospitalDoctorName': null
    //"isHospitalDoctor":0
  };


  public pickerOptions: any = {
    disabledDate: (time: { getTime: () => number; }) => {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
    },
    // 禁用日期
    selectableRange: `${Date().split(' ')[4]} - 23:59:59` // 打开默认当前时间
  };
  public startDateOptions: any = {
    disabledDate: (time: { getTime: () => number; }) => {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
    }
  };
  public endDateOptions: any = {
    disabledDate: (time: { getTime: () => number; }) => {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
    }
  };

  public startDateChange() {
    console.log(this.formInfo.startDateTime, 'startDate');

    // Convert startDateTime string to Date object
    const startDate = new Date(this.formInfo.startDateTime);

    // Update endDateOptions disabledDate based on selected start date
    this.endDateOptions.disabledDate = (time: { getTime: () => number; }) => {
      // Convert time to Date object
      const currentDate = new Date(time.getTime());

      // Disable dates before selected start date
      return currentDate.getTime() < startDate.getTime();
    };
  }


  private userGroupingList: any[] = [
    {
      'id': 1,
      'title': '日'
    },
    {
      'id': 2,
      'title': '周'
    },
    {
      'id': 3,
      'title': '月'
    },
    {
      'id': 4,
      'title': '年'
    }
  ];
  @Inject(CacheService) private cacheSvc!: CacheService;
  private userInfo: any = this.cacheSvc.get('user');
  public sortOrder: string = '';
  public disabledisUserGroups: Boolean = false;
  public title2: string = '远程助手发布';
  public dialogVisible2: boolean = false;
  public dialogTable: boolean = false;
  public dialogOffShelf: boolean = false;
  private batchListingList: any[] = [];
  private isHospitalDoctorList: any[] = [
  ];
  public cur3: Number = -1;
  public buttonLoading: boolean = false;
  public fileClose: boolean = false;
  public title: string = '批量导入';
  public dialogVisible: boolean = false;
  public title1: string = '附件批量导入';
  public isHospitalDoctorTitle: string = '';
  public isHospitalDoctorValue: string = '';
  public HosOrDocObj: any = {};
  public isHospitalDoctorObj: any = {};
  public isHospitalDoctorDialog: boolean = false;
  public dialogVisible1: boolean = false;
  public title3: string = '维护明细';
  public dialogVisible3: boolean = false;
  public typeId: any[] = [];
  private tableData: any[] = [];
  private tableData2: any[] = [];
  private tableData3: any[] = [
    { 'col1': '日', 'col2': '从3月31日起至5月1日结束，每天发布一个问卷任务，一共会发送31个问卷任务' },
    { 'col1': '周', 'col2': '从3月31日起至5月1日结束，每逢周日发布一个问卷任务，一共会发送5个问卷任务' },
    {
      'col1': '月',
      'col2': '从3月31日起至5月1日结束，会分别在3月31日及4月30日分别发送一个问卷任务，一共会发送2个问卷任务'
    },
    { 'col1': '年', 'col2': '从3月31日起至5月1日结束，每逢3月31日发布一个问卷任务，一共会发送1个问卷任务' }
  ];
  private tableList: any[] = [];
  private selectedData: any[] = [];// 存储选中的数据
  private tableData2RowId: string = '';
  public applications: any[] = [];
  public CreateTime: any[] = [];//创建时间
  public publicTime: any[] = [];//发布时间
  public kingList: any[] = [];//根据专栏获取分类
  public loading: Boolean = true;
  public kindshow: Boolean = false;
  private currentRow: any = null;
  private linkPre: string = '';
  private radio1: string = '1';
  public rules: any = {

    // deadlineDay: [
    //   { required: true, message: '请输入', trigger: 'blur' }
    // ],
    releaseDateTime: [
      { required: true, message: '请选择发布开始时间', trigger: 'blur' }
    ],
    startDateTime: [
      { required: true, message: '请选择发布开始时间', trigger: 'blur' }
    ],
    endDateTime: [
      { required: true, message: '请选择发布结束时间', trigger: 'blur' }
    ],
    timeType: [
      { required: true, message: '请选择', trigger: ['change', 'blur'] }
    ],
    time: [
      { required: true, message: '请选择发布时间', trigger: 'blur' }
    ]


  };

  @Inject(SurveyService) private surveyService!: SurveyService;

  public async created(): Promise<void> {
    this.form.userEmail = this.userInfo.email;
    this.form.userCode = this.userInfo.employeeId;
    // const date = new Date();
    // const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, '0');
    // const day = String(date.getDate()).padStart(2, '0');
    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = String(date.getSeconds()).padStart(2, '0');
    // this.formInfo.releaseDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    //this.formInfo.startDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    await this.getList();
  }


  public formatDat(value: any) {
    if (value) {
      return new Date(value).toLocaleString();
    }
  }

  // 重置
  public reset() {
    var that = this;
    that.form = {
      total: 0,
      current: 1,
      size: 10,
      userEmail: that.userInfo.email,
      userCode: that.userInfo.employeeId
    };
    that.typeId = [];
    that.CreateTime = [];
    that.publicTime = [];
    this.getList();
  }

  public search() {
    this.form.current = 1;
    if (this.typeId != undefined && this.typeId != null) {
      let arr = this.typeId;
      this.form.typeIds = arr.join(',');
    }

    if (this.CreateTime && this.CreateTime.length > 0) {
      this.form.startTime = this.CreateTime[0];
      this.form.endTime = this.CreateTime[1];
    } else {
      this.form.startTime = '';
      this.form.endTime = '';
    }

    if (this.publicTime && this.publicTime.length > 0) {
      this.form.startDate = this.publicTime[0];
      this.form.endDate = this.publicTime[1];
    } else {
      this.form.startDate = '';
      this.form.endDate = '';
    }

    this.getList();
  }

  //分页相关
  public handleSizeChange(size: number) {
    console.log(size, 'size');
    this.form.size = size;
    this.getList();
  }

  public handleCurrentChange(current: number) {
    console.log(current, 'current');
    this.form.current = current;
    this.getList();
  }

  //分页相关
  public handleSizeChange2(size: number, row: any) {
    this.queryParams.size = size;
    this.queryParams.surveyId = this.tableData2RowId;
    this.dialogVisible3 = true;
    this.surveyService.detailLog(this.queryParams).then((res: any) => {
      this.tableData2 = res.records;
      this.queryParams.total = res.total;
    });
  }

  public handleCurrentChange2(current: number, row: any) {
    this.queryParams.current = current;
    this.queryParams.surveyId = this.tableData2RowId;
    this.dialogVisible3 = true;
    this.surveyService.detailLog(this.queryParams).then((res: any) => {
      this.tableData2 = res.records;
      this.queryParams.total = res.total;
    });
  }

  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.surveyService.surveyList(this.form);
    this.loading = false;
    let data: any = res.records;
    await this.formData(data);
    this.form.total = res.total;
    //this.form.pages = res.pages;
    this.form.size = res.size;
    //this.form.current = res.current;
  }

  //处理专栏id对应的name
  public async formData(res: any) {
    this.tableData = res;
  }

  //获取专栏
  public async applicationsList(): Promise<void> {
    const res = await this.surveyService.applicationsList();
    this.applications = res;
    for (var i = 0; i < this.applications.length; i++) {
      this.applications[i].channelAgentId = Number(this.applications[i].channelAgentId);
    }
  }


  //附件批量导入
  public async filebatchUpload() {
    this.dialogVisible1 = true;
    this.fileClose = false;
    //(this.$refs.childDialog as openDialog).upFile();
  }


  //批量导入下载模板
  public async downTemplate() {
    const fileUrl = 'https://wecomfoundation.novartis.com.cn/surveyTemplate/远程线索反馈导入表模板.xlsx'; // 替换为你要下载的文件URL
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '远程线索反馈导入表模板.xlsx'); // 设置下载文件的文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // try {
    //   const response = await fetch(fileUrl);
    //   const blob = await response.blob();
    //
    //   if (window.navigator.msSaveBlob) { // 检查是否支持 msSaveBlob 方法
    //     window.navigator.msSaveBlob(blob, '问卷星新增模板.xlsx'); // 直接保存 Blob 对象为文件
    //   } else {
    //     const link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     link.setAttribute('download', '问卷星新增模板.xlsx'); // 设置下载文件的文件名
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   }
    // } catch (error) {
    //   console.error('下载文件时出错：', error);
    // }
  }

  //线索导出
  public async impSurveyQuestionnaire() {
    if (this.publicTime && this.publicTime.length > 0) {
      this.form.startDate = this.publicTime[0];
      this.form.endDate = this.publicTime[1];
    } else {
      this.form.startDate = '';
      this.form.endDate = '';
    }
    this.loading = true;
    const res = await this.surveyService.impSurveyQuestionnaire(this.form, '当前文件线索导出详情.xlsx');
    this.loading = false;
  }


  //批量导入
  public async batchUpload() {
    this.dialogVisible = true;
  }

  //得到子组件传过来的值
  public getChild(val: any): void {
    var that = this;
    that.dialogVisible = false;
    that.getList();
  }

  //得到子组件传过来的值
  public getChild2(val: any): void {
    var that = this;
    that.dialogVisible1 = false;
    that.getList();
  }


  //删除图文
  private async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    };
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.surveyService.articleDelete(param).then((res: any) => {
        if (res) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        }

      }).catch((err: any) => {
        console.log(err, 'err');
      });

    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }

  public filterHandler(value: any, row: any, column: any) {
    const property = column['property'];
    return row[property] === value;
  }

  public submitForm2(formName: any): void {
    //this.formInfo.startTime = this.formInfo.time[0];
    //this.formInfo.endTime = this.formInfo.time[1];
    const addRef = (this.$refs.formInfo as any);
    addRef.validate(async (valid: any) => {
      if (valid) {
        this.buttonLoading = true;
        this.batchListingList.map((item: any) => {
          console.log(this.formInfo.isCirculate, 'item.isCirculate');
          if (this.formInfo.isCirculate == 1) {
            item.releaseDateTime = this.formInfo.time[0];
          } else {
            item.releaseDateTime = this.formInfo.releaseDateTime;
          }
          item.publishStatus = 1;
          //item.startTime = this.formInfo.time[0];
          //item.endTime = this.formInfo.time[1];
          item.startDateTime = this.formInfo.time[0];
          item.endDateTime = this.formInfo.time[1];
          item.deadlineDay = this.formInfo.deadlineDay;
          item.typeName = this.formInfo.typeName;
          item.timeType = this.formInfo.timeType;
          item.isCirculate = this.formInfo.isCirculate;
          item.userLoginName = this.userInfo.fullName;
          item.userLoginCode = this.userInfo.employeeId;
        });
        await this.surveyService.updatePublishStatusList({ 'surveyQuestionnaires': this.batchListingList }).then((res: any) => {
          if (res) {
            this.$message({
              message: '发布成功',
              type: 'success'
            });
            this.getList();
            this.dialogVisible2 = false;
            this.buttonLoading = false;
          } else {
            this.buttonLoading = false;
          }

        }).catch((err: any) => {
          this.dialogVisible2 = false;
          this.buttonLoading = false;
        });

        // this.buttonLoading = false;
      } else {
        // this.$message({
        //   type: 'info',
        //   message: '请输入对应字段'
        // });
        this.buttonLoading = false;
        this.buttonLoading = false;
        return false;
      }

    });
    console.log(this.formInfo, 'formInfo');
  }

  public cancel() {
    this.dialogVisible3 = false;
  }

  //维护明细
  public handDetails(row: any) {
    this.queryParams.current = 1;
    this.tableData2RowId = row.surveyId;
    this.queryParams.surveyId = row.surveyId;
    this.dialogVisible3 = true;
    this.surveyService.detailLog(this.queryParams).then((res: any) => {
      this.tableData2 = res.records;
      this.queryParams.total = res.total;
    });
  }

  handleSelectionChange(selection: any) {
    this.selectedData = selection;
  }

//批量下架
  public batchDelisting() {
    if (this.selectedData.some(item => (item.publishStatus === 2) || (item.publishStatus === 0))) {
      this.$message('选中数据中包含已下架的数据，请重新选择！');
      return;
    }
    this.handOffShelf(this.selectedData);
  }

  //批量发布
  public batchListing() {
    if (this.selectedData.some(item => (item.publishStatus !== 0))) {
      this.$message('只有首次创建的问卷（草稿状态）才允许批量发布，请重新选择！');
      return;
    }

    // // 获取第一个条目的 isCirculate 值作为基准
    // const baseIsCirculate = this.selectedData[0].isCirculate;
    //  // 验证所有条目的 isCirculate 是否与基准值相同
    // const isValid = this.selectedData.every(item => item.isCirculate === baseIsCirculate);
    // if (isValid) {
    //   console.log('所有条目的 isCirculate 值相同，符合要求。');
    //
    //   if(baseIsCirculate == 1){
    //     this.formInfo = {
    //       ... this.formInfo,
    //       'deadlineDay': null,
    //       'typeName': null,
    //       'timeType': null,
    //       'startDateTime': null,
    //       'endDateTime': null,
    //       'time': [],
    //     }
    //     console.log('都是循环')
    //     const canEdit = this.selectedData.some(item => item.isRelease === 0 || item.publishStatus === 0);
    //     const cannotEdit = this.selectedData.some(item => item.isRelease === 1  || item.publishStatus === 2);
    //     console.log(canEdit,'canEdit')
    //     console.log(cannotEdit,'cannotEdit')
    //     if (canEdit && !cannotEdit) {
    //       console.log('可以编辑');
    //       this.formInfo.isCirculate = 0
    //       this.disabledisUserGroups = false;
    //     } else if (!canEdit && cannotEdit) {
    //       console.log('不可编辑');
    //       this.formInfo.isCirculate = baseIsCirculate
    //       this.disabledisUserGroups = true;
    //     }else{
    //       console.log('不可编辑');
    //       this.formInfo.isCirculate = baseIsCirculate
    //       this.disabledisUserGroups = true;
    //     }
    //   }
    //   else if(baseIsCirculate == 0){
    //     console.log('都是单次')
    //     // 如果都是单次发布，进一步验证 isRelease 和 publishStatus
    //     const canEdit = this.selectedData.some(item => item.isRelease === 0 || item.publishStatus === 0);
    //     const cannotEdit = this.selectedData.some(item => item.isRelease === 1  || item.publishStatus === 2);
    //     this.formInfo = {
    //       ... this.formInfo,
    //       'releaseDateTime':"",
    //     }
    //     if (canEdit && !cannotEdit) {
    //       console.log('可以编辑');
    //       this.formInfo.isCirculate = 0
    //       this.disabledisUserGroups = false;
    //     } else if (!canEdit && cannotEdit) {
    //       console.log('不可编辑');
    //       this.formInfo.isCirculate = baseIsCirculate
    //       this.disabledisUserGroups = true;
    //     }else{
    //       console.log('不可编辑');
    //       this.formInfo.isCirculate = baseIsCirculate
    //       this.disabledisUserGroups = true;
    //     }
    //   }
    //   this.dialogVisible2 = true;
    // } else {
    //   this.$message('未发布默认为单次发布状态，选中数据中包含已发布为单次或循环同时选择的数据，请重新选择！');
    //   return;
    //   this.dialogVisible2 = false;
    // }
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    this.formInfo = {
      ...this.formInfo,
      'releaseDateTime': `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
      'isCirculate': 0
    };
    this.disabledisUserGroups = false;
    this.dialogVisible2 = true;
    this.batchListingList = this.selectedData;
  }

  public inputChange(e: any) {
    console.log(e, 'e');
    if (e <= 0) {
      this.formInfo.deadlineDay = 1;
    }
  }

  public changeRadio() {
    console.log(this.formInfo.isCirculate == 0 ? '单次' : '循环', '发布频率');
  }

  public formInfoClose() {
    console.log('formInfoClose');
    this.formInfo.isCirculate = 0;

    const addRef = (this.$refs.formInfo as any);
    addRef.clearValidate();
  }

  //发布
  public handRelease(row: any) {
    console.log(row[0].isCirculate == 0 ? '单次' : '循环', '发布频率');
    console.log(row[0].isRelease == 1 ? '发布过' : '没发布过', '发布');
    //this.formInfo.isCirculate = row[0].isCirculate
    if (row[0].isRelease == 1) {
      this.disabledisUserGroups = true;
    } else {
      this.disabledisUserGroups = false;
    }
    this.buttonLoading = false;
    this.batchListingList = row;
    console.log(this.formInfo, 'formInfo');
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    this.formInfo = {
      ...this.formInfo,
      'releaseDateTime': row[0].isRelease == 1 ? '' : `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
      'deadlineDay': null,
      'typeName': null,
      'timeType': null,
      'startDateTime': null,
      'endDateTime': null,
      'time': [],
      'isCirculate': row[0].isRelease == 1 ? row[0].isCirculate : this.formInfo.isCirculate
    };
    //this.formInfo.time = [new Date(row[0].startTime), new Date(row[0].endTime)],
    this.dialogVisible2 = true;
  }

  public offShelfCancel() {
    this.$message({
      type: 'info',
      message: '已取消下架'
    });
    this.dialogOffShelf = false;
  }

  public async offShelfConfirm() {
    this.batchListingList.map((item: any) => {
      item.publishStatus = 2;
      // item.startDateTime = this.formInfo.time[0];
      // item.endDateTime = this.formInfo.time[2];
      //  item.startTime = this.formInfo.time[0];
      //  item.endTime = this.formInfo.time[1];
      //  item.deadlineDay = this.formInfo.deadlineDay;
      //  item.typeName = this.formInfo.typeName;
      //  item.timeType = this.formInfo.timeType;
      //  item.isCirculate = this.formInfo.isCirculate;
      //  item.releaseDateTime = this.formInfo.releaseDateTime;
      item.userLoginName = this.userInfo.fullName;
      item.userLoginCode = this.userInfo.employeeId;
      item.isHistory = this.radio1;
    });
    // await this.surveyService.updatePublishStatus(this.formInfo);
    const res = await this.surveyService.updatePublishStatusList({ 'surveyQuestionnaires': this.batchListingList }).then((res: any) => {

      if (res) {
        this.$message({
          message: '下架成功',
          type: 'success'
        });
        this.getList();

        this.buttonLoading = false;
      } else {
        this.buttonLoading = false;
      }

    }).catch((err: any) => {

      this.buttonLoading = false;
    });


    this.dialogOffShelf = false;
  }

  //下架
  public async handOffShelf(row: any) {
    console.log(row, '下架');
    this.batchListingList = row;
    this.radio1 = '1';
    this.dialogOffShelf = true;

  }

  //预览
  handPreview(row: any, type: any) {
    console.log(row, '预览');
    if (type == 1) {
      let link = `${location.origin}/wecom-mfa-front/#/surveyQuestionnaire?surveyId=` + row.surveyId + '&surveyLoopQuestionnaireId=' + row.surveyLoopQuestionnaireId + '&userPost=' + this.userInfo.userPost + '&userPostId=' + this.userInfo.userPostId + '&type=0' + '&userCode=' + this.userInfo.employeeId + '&typeName=' + row.typeDictionaryName;
      window.open(link);
    } else {

      if ((row.publishStatus == 1 && row.isCirculate == 0) && (row.postUserList.surveyImportUserPosts != null && row.postUserList.surveyImportUserPosts.length == 1)) {
        if(row.isHospitalDoctor==null){
          let link = `${location.origin}/wecom-mfa-front/#/surveyQuestionnaire?surveyId=` + row.surveyId + '&surveyLoopQuestionnaireId=' + row.surveyLoopQuestionnaireId + '&userPost=' + row.postUserList.surveyImportUserPosts[0].userPost + '&userPostId=' + row.postUserList.surveyImportUserPosts[0].userPostId + '&type=0' + '&userCode=' + this.userInfo.employeeId + '&typeName=' + row.typeDictionaryName + '&publishStatus=' + row.publishStatus + '&postUserList=' + row.postUserList + '&isCirculate=' + row.isCirculate + '&isHospitalDoctor=' + '&isHospitalDoctorValue=' + this.HosOrDocObj.hospitalDoctorName + '&isHospitalDoctorId='  ;
          window.open(link);
        }
        else{
          row.isHospitalDoctor == 1 ? this.isHospitalDoctorTitle = '目标医院' : this.isHospitalDoctorTitle = '目标医生';
          if( row.isHospitalDoctor == 1){
            this.isHospitalDoctorTitle = '目标医院'
            this.getHospitalList(this.formInline.spmHospitalDoctorName, row.postUserList.surveyImportUserPosts[0].userPost, row.postUserList.surveyImportUserPosts[0].userPostId);
          }
          else{
            this.isHospitalDoctorTitle = '目标医生';
            this.getDoctorList(this.formInline.spmHospitalDoctorName, row.postUserList.surveyImportUserPosts[0].userPost, row.postUserList.surveyImportUserPosts[0].userPostId);
          }

          this.isHospitalDoctorObj = row;
          this.isHospitalDoctorDialog = true;
        }


      } else {
        this.$message({
          type: 'info',
          message: '只有发送给单人、单次未作答的调研问卷，才允许协助作答!'
        });
      }

    }
  }

  public async getHospitalList(spmHospitalDoctorName: any, userPost: any, userPostId: any): Promise<void> {
    await this.surveyService.surveySpmHospitalList({
      'spmHospitalDoctorName': spmHospitalDoctorName,
      'userPost': userPost,
      'userPostId': userPostId
    }).then((res) => {

      this.isHospitalDoctorList =res
    }).catch((err) => {
      console.log(err,'getHospitalList')
    });
  }
  public async getDoctorList(spmHospitalDoctorName: any, userPost: any, userPostId: any): Promise<void> {
    await this.surveyService.surveySpmDoctorList({
      'spmHospitalDoctorName': spmHospitalDoctorName,
      'userPost': userPost,
      'userPostId': userPostId
    }).then((res) => {
      this.isHospitalDoctorList =res
    }).catch((err) => {
      console.log(err,'getHospitalList')
    });
  }
 public closeHospitalDoctor(){
   this.formInline.spmHospitalDoctorName = ''
    this.cur3 = -1
   this.HosOrDocObj = {}

 }
  public spmHospitalDoctorNameSearch() {
    this.cur3 = -1
    this.HosOrDocObj = {}
    if( this.isHospitalDoctorObj.isHospitalDoctor == 1){
      this.getHospitalList(this.formInline.spmHospitalDoctorName, this.isHospitalDoctorObj.postUserList.surveyImportUserPosts[0].userPost, this.isHospitalDoctorObj.postUserList.surveyImportUserPosts[0].userPostId);
    }
    else{
      this.getDoctorList(this.formInline.spmHospitalDoctorName, this.isHospitalDoctorObj.postUserList.surveyImportUserPosts[0].userPost, this.isHospitalDoctorObj.postUserList.surveyImportUserPosts[0].userPostId);
    }
  }
  public isHospitalDoctorConfirm() {
    console.log(this.HosOrDocObj.hospitalDoctorName,'this.HosOrDocObj')
    if(this.HosOrDocObj.hospitalDoctorName){
      this.isHospitalDoctorDialog = false;
      this.cur3 = -1
      let link = `${location.origin}/wecom-mfa-front/#/surveyQuestionnaire?surveyId=` + this.isHospitalDoctorObj.surveyId + '&surveyLoopQuestionnaireId=' + this.isHospitalDoctorObj.surveyLoopQuestionnaireId + '&userPost=' + this.isHospitalDoctorObj.postUserList.surveyImportUserPosts[0].userPost + '&userPostId=' + this.isHospitalDoctorObj.postUserList.surveyImportUserPosts[0].userPostId + '&type=0' + '&userCode=' + this.userInfo.employeeId + '&typeName=' + this.isHospitalDoctorObj.typeDictionaryName + '&publishStatus=' + this.isHospitalDoctorObj.publishStatus + '&postUserList=' + this.isHospitalDoctorObj.postUserList + '&isCirculate=' + this.isHospitalDoctorObj.isCirculate + '&isHospitalDoctor=' + this.isHospitalDoctorObj.isHospitalDoctor + '&isHospitalDoctorValue=' + this.HosOrDocObj.hospitalDoctorName + '&isHospitalDoctorId=' + this.HosOrDocObj.spmHospitalDoctorId;
      window.open(link);
    }
    else{
      this.$message("请选择");
    }
  }

  public HosOrDocLiClick(item: any, index: any) {
    this.cur3 = index;
    this.HosOrDocObj = item;
  }

  //排序
  public sortTable(type: any, sort: any) {
    console.log(sort, 'sort');
    this.form.fieldName = type;
    this.form.fieldByDesc = sort;
    this.sortOrder = sort;
    this.getList();
  }


  handDownLoad(row: any) {
    console.log(row, '下载');
    const fileUrl = row.attachmentUrl; // 替换为你要下载的文件URL
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', row.attachmentName); // 设置下载文件的文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //修改
  public handUpdate(row: any) {
    localStorage.setItem('surveyType', row.id);
    this.$router.push('/addSurvey');
  }


  //创建
  public createSurvey() {
    localStorage.setItem('surveyType', '000');
    this.$router.push('/addSurvey');
  }

  public selectChange(e: any) {
    console.log(e, 'e');
    if (e == ' ') {
      this.form.surveyIsSubmit = 1;
    } else {
      this.form.surveyIsSubmit = '';
    }
  }

  //关闭弹窗
  public closeDialog1(): void {
    this.dialogVisible = false;
    this.tableList = [];
  }

  public closeDialog2(): void {
    this.getList();
    this.dialogVisible1 = false;
    this.fileClose = true;
  }

  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search();
      }
    };
  }
}
