















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
import { SurveyService } from '@/services/survey-service';

import { AjaxError } from 'rxjs/ajax';

@Component({
  components: {}
})
export default class openDialog extends Vue {

  private tableData: any[] = [];
  public list: any[] = [];
  public audioDuration: number = 0;
  public fileList: any[] = [];
  public actionUrl: any = '';
  public loading: any = false;
  public loading2: any = false;

  public formData: any = {
    filessrc: ''
  };
  public formLabelWidth: any = '140px';
  public showList: any = false;
  public errorMes: any = false;
  @Inject(CacheService) private cacheSvc!: CacheService;
  private userInfo: any = this.cacheSvc.get('user');
  @Prop({ default: [] }) private tableList!: any[];

  @Watch('tableList', { immediate: true, deep: true })
  onChangeValue(newVal: any, oldVal: any) {
    console.log(newVal, oldVal, '监听tableList');
    this.tableData = newVal;
    this.errorMes = false;
  }

  public rules: any = {
    channelId: [
      { required: true, message: '请选择', trigger: ['blur', 'change'] }],
    filessrc: [
      { required: true, message: '请上传', trigger: ['blur', 'change'] }]
  };
  @Inject(statisticsApi) private statisticsApi!: statisticsApi;
  @Inject(SurveyService) private surveyService!: SurveyService;

  public created(): void {
    this.formData.filessrc = '';
    this.fileList = [];

    //this.actionUrl ='https://dev.wecomfoundation.novartis.com.cn/wecom-survey-api/admin/servey/upload';
    this.actionUrl = document.location.origin + '/wecom-survey-api/admin/servey/upload';

  }

  public beforeAvatarUpload(files: { type: string; size: number; name: string; userEmail: any; userCode: any; formData: any }) {
    console.log('beforeAvatarUpload');
    // 文件类型进行判断
    this.loading = true;

    files.userEmail = this.userInfo.email;
    files.userCode = this.userInfo.employeeId;
    var fileType = files.name.substring(files.name.lastIndexOf('.') + 1);
    console.log('文件类型进行判断', files);
    const typeOne = fileType === 'xlsx';
    const isLt20M = files.size / 1024 / 1024 < 20;
    if (!typeOne) {
      this.$message.error('上传文件格式错误!');
      this.fileList = [];
      this.audioDuration = 0;
      this.formData.filessrc = '';
      return false;
    } else {

    }
    this.audioDuration = 0;
    return typeOne;
  }

  // 监控上传文件列表
  public uploadChange(file: any, fileList: any) {
    console.log(file, '监控上传文件列表');

    file.userEmail = this.userInfo.email;
    file.userCode = this.userInfo.employeeId;
    let existFile = fileList
      .slice(0, fileList.length - 1)
      .find((f: any) => f.name === file.name);
    if (existFile) {
      this.$message.error('当前文件已经存在!');
      fileList.pop();
    }
    this.fileList = fileList;
  }

  public async uploadClick(formName: any): Promise<void> {
    console.log(this.formData, 'this.formData');
    const addRef = (this.$refs.formData as any);
    // let formData = new FormData();
    // this.fileList.forEach((item) => {
    //   formData.append('file', item.raw);
    // });
    // formData.append('userEmail', this.userInfo.email);
    // formData.append('userCode', this.userInfo.employeeId);
    //
    // await this.statisticsApi.surveyUpload(formData).then((res) => {
    //   console.log(res, 'res');
    //   if (res == '上传成功') {
    //     this.$message.success('上传成功');
    //   }
    //   else{
    //     console.log("aaaaa")
    //   }
    // }).catch((err) => {
    //   console.log(err, 'err');
    // });
    // return;
    addRef.validate(async (valid: any) => {
      if (valid) {
        var data_ = {
          'file': this.formData.filessrc,
          'userEmail': this.userInfo.email,
          'userCode': this.userInfo.employeeId
        };
        // 本地文件上传
        if (this.formData.filessrc != '' && this.formData.filessrc != null && this.formData.filessrc != undefined) {
          const res = await this.statisticsApi.surveyUpload(data_);
          this.formData.filessrc = '';
          this.fileList = [];
          this.handleRemove();
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          this.getChild();
          this.loading2 = true;
          this.loading = false;

        } else {
          this.$message.error('请上传文件!');
          this.loading = false;
        }

      }
    });

  }

  public customUpload(file: any) {
    console.log('customUpload');
    let formData = new FormData();
    this.fileList.forEach((item) => {
      formData.append('file', item.raw);
    });
    formData.append('userEmail', this.userInfo.email);
    formData.append('userCode', this.userInfo.employeeId);

    this.statisticsApi.surveyUpload(formData).then((res) => {
      console.log(res, 'res');
      if (res[0].state == '成功') {
        this.$alert('线索导入成功!', '提示', {
          confirmButtonText: '确定',
          type: 'success',
          showClose: false

        }).then(() => {

          const obj = JSON.stringify(res);
          var ids = res.map((item: any) => {
            return item.questionnaireId;
          });
          //this.surveyService.updateExcel({ 'ids':ids}, '线索导出成功.xlsx')
          this.getChild();
        });
        this.errorMes = false;
        this.tableData = [];
        this.loading2 = true;
        this.loading = false;
      } else if (res[0].state == '失败') {
        this.$message('导入失败');
        this.errorMes = true;
        this.tableData = res;
        this.loading = false;
        this.loading2 = false;
        //this.getChild();
        this.fileList = [];
        this.formData.filessrc = '';
        this.handleRemove();
      }

    }).catch((err) => {
      //this.errorUpload(err);
      this.handleRemove();
      this.loading = false;
      this.errorMes = false;
      this.tableData = [];
    });
    return;
  }

  public async handleRemove() {
    console.log('handleRemove');
    this.fileList = [];
    this.formData.filessrc = '';

  }

  public async handlePreview(file: any) {
    console.log(file, 'handlePreview');
  }

  public async successUpload(response: any) {
    console.log('批量导入成功监听', response);
    if (response.header.code == 200) {
      //this.getChild();
      await this.$alert('线索导入成功!', '提示', {
        confirmButtonText: '确定',
        type: 'success'

      }).then(() => {
        this.getChild();
        const obj = JSON.stringify(response.body);
        console.log(response.body, 'obj');
        var ids = response.body.map((item: any) => {
          return item.questionnaireId;
        });
        this.surveyService.updateExcel({ 'ids': ids }, '线索导出成功.xlsx');

      });
      this.errorMes = false;
      this.tableData = [];
    } else {
      this.$message(response.header.message);
      this.errorMes = true;
      this.tableData = response.body;
      //this.getChild();
      this.fileList = [];
      this.formData.filessrc = '';
      this.handleRemove();
    }
    this.loading = false;
  }

  public async errorUpload(response: any) {
    console.log('error', response);
    if (response.header.code == 200) {
      //this.getChild();
      this.fileList = [];
      this.formData.filessrc = '';
    } else {
      this.$message(response.header.message);

    }
  }

  // 去后缀名 验证是否正确
  public getfileName(name: any) {
    // var suffix = name.substring(name.lastIndexOf('.'));
    var suffix = name.substring(name.lastIndexOf('.') + 1);
    if (suffix == 'word' || suffix == 'excel' || suffix == 'ppt' || suffix == 'pdf' || suffix == 'doc' || suffix == 'pptx') {
      return true;
    } else {
      return false;
    }

  }

  @Emit('getChild')
  public getChild(): any {
    this.handleRemove();
    this.formData.filessrc = '';
    this.fileList = [];
    this.loading = false;
    this.loading2 = false;
    this.formData = {
      'agentId': '',
      'url': '',
      'filessrc': ''
    };
    return true;
  }

  /**
   * -END- CALL SERVICE
   */
}
