




























































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里

@Component({
  components: {}
})
export default class openDialog extends Vue {
  @Prop({ default: false }) private fileClose!: Boolean;
  public list: any[] = [];
  public audioDuration: number = 0;
  public fileList: any[] = [];
  public actionUrl: any = '';
  public formData: any = [];
  public dataList: any = [];
  public formLabelWidth: any = '140px';
  public showList: any = false;
  public dropDown: any = [];

  public rules: any = {
    channelId: [
      { required: true, message: '请选择', trigger: ['blur', 'change'] }],
    filessrc: [
      { required: true, message: '请上传', trigger: ['blur', 'change'] }]
  };
  @Inject(statisticsApi) private statisticsApi!: statisticsApi;
  @Inject(CacheService) private cacheSvc!: CacheService;
  @Watch('fileClose', { immediate: true, deep: true })
  onChangeValue(newVal: any, oldVal: any) {
      if(newVal){

        this.fileList = [];
        this.formData = [];
      }
    this.getDropDown();
  }
  private userInfo: any = this.cacheSvc.get('user');
  public created(): void {
    this.formData = [];
    this.fileList = [];
    this.actionUrl = document.location.origin + '/wecom-survey-api/survey/attachment/batch/upload';
    //  this.actionUrl = 'https://dev.wecomfoundation.novartis.com.cn/wecom-survey-api/survey/attachment/batch/upload';

  }

  // 拖拽上传
  public beforeRemove(file: any, fileList: any) {
    this.fileList = fileList;
    // return this.$confirm(`确定移除 ${file.name}？`);
  }

  // 移除附件
  public upLoadRemove(file: any) {
    let tempFileList = [];
    for (var index = 0; index < this.fileList.length; index++) {
      if (this.fileList[index].name !== file.name) {
        tempFileList.push(this.fileList[index]);
      }
    }
    this.fileList = tempFileList;
  }


  // 监控上传文件列表
  public uploadChange(file: any, fileList: any) {
    console.log(file, fileList, '监控上传文件列表');
    let existFile = fileList
      .slice(0, fileList.length - 1)
      .find((f: any) => f.name === file.name);
    if (existFile) {
      this.$message.error('当前文件已经存在!');
      fileList.pop();
    }
    this.fileList = fileList;
  }

  // 上传到服务器  formidable接收
  async submitUpload() {

    const isLt100M = this.fileList.every(
      (file) => file.size / 1024 / 1024 < 100
    );
    let formData = new FormData();
    this.fileList.forEach((item) => {
      const newFile = new File([item.raw], item.id + '_' + item.raw.name, { type: item.raw.type });
      formData.append('files', newFile);
    });
    for (let i = 0; i < this.fileList.length; i++) {
      if (!this.fileList[i].hasOwnProperty('id')) {
        this.$message("请关联问卷");
        return false;
      }
    }

    this.$message("上传中");
    await this.statisticsApi.surveyBatchUpload(formData).then((res) => {
      if (res == '上传成功') {
        this.$message.success('上传成功');
        this.getChild2();
      }
    }).catch((err) => {
      console.log(err, 'err');
    });
  }

  // 点击文件进行下载
  public downLoadFile(file: any, fileList: any) {
    var a = document.createElement('a');
    var event = new MouseEvent('click');
    a.download = file.name;
    a.href = file.url;
    a.dispatchEvent(event);
  }


  // public beforeAvatarUpload(files: { type: string; size: number; name: string,raw:any }) {
  //   // 文件类型进行判断
  //   console.log('beforeAvatarUpload', files);
  //
  //
  //   const allowedExtensions = ['xlsx', 'xls', 'csv', 'xlsb', 'mp3', 'wav', 'ogg', 'mp4', 'jpg', 'jpeg', 'png', 'bmp', 'heic'];
  //   const maxSize = 10 * 1024 * 1024; // 10MB
  //
  //   const extension = files.name.substring(files.name.lastIndexOf('.') + 1);
  //
  //   if (!allowedExtensions.includes(extension)) {
  //     this.$message.error('不支持的文件类型');
  //     return false; // 阻止文件上传
  //   }
  //
  //   if (files.size > maxSize) {
  //     this.$message.error('文件大小超过限制');
  //     return false; // 阻止文件上传
  //   }
  //
  //   // // 其他验证逻辑...
  //   // this.fileList = this.formData.map((item:any)=>{
  //   //   return {name:item.name,url:item.url}
  //   // })
  //   // console.log(this.fileList,'this.fileList')
  //   //
  //   //
  //   //
  //   // return true; // 允许文件上传
  //
  //
  // }
  //
  // public async uploadClick(formName: any): Promise<void> {
  //
  //
  //
  //
  //   // const addRef = (this.$refs.formData as any);
  //   // const addRefs = (this.$refs.uploadRef as any);
  //   //
  //   //
  //   // addRef.validate(async (valid: any) => {
  //   //   if (valid) {
  //   //    console.log(valid, 'valid');
  //   //   //  console.log(this.fileList,'this.validate')
  //   //    // addRefs.submit();
  //   //     console.log(this.formData,'this.formData')
  //   //     var data_ = {
  //   //       'files': this.formData.filessrc.map((item:any)=>{
  //   //         return item.raw
  //   //       })
  //   //     };
  //   //     //本地文件上传
  //   //     if (this.formData.filessrc != '' && this.formData.filessrc != null && this.formData.filessrc != undefined) {
  //   //       const res = await this.statisticsApi.surveyBatchUpload(data_);
  //   //       console.log(res, 'res');
  //   //       // this.formData.filessrc = '';
  //   //       // this.fileList = [];
  //   //       // this.handleRemove();
  //   //       // this.$message({
  //   //       //   message: '上传成功',
  //   //       //   type: 'success'
  //   //       // });
  //   //       this.getChild2();
  //   //     } else {
  //   //       this.$message.error('请上传文件!');
  //   //     }
  //   //
  //   //   }
  //   //});
  //
  // }
  //
  // public async handleRemove() {
  //   this.fileList = [];
  //   this.formData.filessrc = [];
  //
  // }
  //
  // public async handlePreview(file: any) {
  //   console.log(file);
  // }
  //
  // public async handleChange(file: any, fileList: any) {
  //   console.log(file, '改变的file');
  //
  //   console.log(fileList, '改变的fileList');
  //   this.formData.filessrc = fileList;
  //
  //
  // }
  //
  // public async successUpload(response: any, file: any, fileList: any) {
  //   console.log('successUpload', response);
  //   console.log(file, '成功上传的file');
  //
  //   console.log(fileList, '成功上传的fileList');
  //   if (response.header.code == 200) {
  //     this.$message({
  //       message: '上传成功',
  //       type: 'success'
  //     });
  //     this.getChild2();
  //     // this.fileList = [];
  //     //this.formData.filessrc = [];
  //   } else {
  //     console.log('未成功');
  //     this.$message(response.header.message);
  //     //this.getChild2();
  //     //this.fileList = [];
  //     //this.formData.filessrc = [];
  //
  //   }
  // }
  //
  // public async errorUpload(response: any, file: any, fileList: any) {
  //   console.log('errorResponse', response);
  //   console.log('errorFile', file);
  //   console.log('errorFileList', fileList);
  //   if (response.header.code == 200) {
  //     this.getChild2();
  //     this.fileList = [];
  //     this.formData.filessrc = [];
  //   } else {
  //     this.$message(response.header.message);
  //
  //   }
  // }

  // 去后缀名 验证是否正确
  public getfileName(name: any) {
    // var suffix = name.substring(name.lastIndexOf('.'));
    var suffix = name.substring(name.lastIndexOf('.') + 1);
    if (suffix == 'word' || suffix == 'excel' || suffix == 'ppt' || suffix == 'pdf' || suffix == 'doc' || suffix == 'pptx') {
      return true;
    } else {
      return false;
    }

  }

  @Emit('getChild2')
  public getChild2(): any {
    // this.upLoadRemove(file,fileList);
    // this.formData.filessrc = [];
    this.fileList = [];
    // this.formData = {
    //   'agentId': '',
    //   'url': '',
    //   'filessrc': []
    // };
    return true;
  }

  public async getDropDown(): Promise<void> {

    const res = await this.statisticsApi.importsSurveyDropDown({userEmail: this.userInfo.email,userCode: this.userInfo.employeeId});
    if (res) {
      this.dropDown = res;
    }
  }

  /**
   * -END- CALL SERVICE
   */
}
