














import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
//引入组件
import VueUeditorWrap from "vue-ueditor-wrap"; //es6

@Component({
  components: {
    VueUeditorWrap
  }
})
export default class openDialog extends Vue {
  public msg: any = '';
  public myConfig = {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 500,
        fileActionName: 'upload',
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: "http://35.201.165.105:8000/controller.php",
        serverUrl:'https://qa.wecomfoundation.novartis.com.cn/wecom-survey-api/survey/attachment/batch/upload',
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "/ueditor/",
        toolbars: [[
            'fullscreen', 'source', '|', 'undo', 'redo', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
            'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'forecolor',
            'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc',
            'fontfamily', 'fontsize', 'simpleupload', 'insertimage','emotion', /*'scrawl',*/
            /*'music',*/ 'insertvideo', 'insertaudio', 'attachment', 'map',
            'rowspacingtop', 'rowspacingbottom', 'lineheight', 'link', 'unlink'
        ]],
      'fontfamily':[
          { label:'',name:'songti',val:'宋体,SimSun'},
          { label:'仿宋',name:'fangsong',val:'仿宋,FangSong'},
          { label:'仿宋_GB2312',name:'fangsong',val:'仿宋_GB2312,FangSong'},
          { label:'',name:'kaiti',val:'楷体,楷体_GB2312, SimKai'},
          { label:'',name:'yahei',val:'微软雅黑,Microsoft YaHei'},
          { label:'',name:'heiti',val:'黑体, SimHei'},
          { label:'',name:'lishu',val:'隶书, SimLi'},
          { label:'',name:'andaleMono',val:'andale mono'},
          { label:'',name:'arial',val:'arial, helvetica,sans-serif'},
          { label:'',name:'arialBlack',val:'arial black,avant garde'},
          { label:'',name:'comicSansMs',val:'comic sans ms'},
          { label:'',name:'impact',val:'impact,chicago'},
          { label:'',name:'timesNewRoman',val:'times new roman'}
      ],
      success: function () {
         alert('12222')
      }
  };
  public myEditorImage: any = '';
  public d: any[] = [];
  public list: any[] = [];
  // i8n
  // @Inject(I18nService) private i18nSvc!: I18nService;

  // @Watch('list', { deep: true })
  // handleWatch(){
  // console.log('监听上面数组的内容')
	// }

  public created(): void {

  }
  public ready (editorInstance : Dict<any>) {
    //设置编辑器不可用(事实上不可以设置不可用...所以注释掉,以观后效)
    editorInstance.setDisabled();
    //隐藏编辑器，因为不会用到这个编辑器实例，所以要隐藏
    editorInstance.hide();
    this.myEditorImage =  editorInstance
    //拿到图片地址
    let _this = this;
    editorInstance.addListener('beforeInsertImage',function(t: Dict<any>, arg: Dict<any>){
        _this.list = arg[0]
        _this.getChild()

    });
      editorInstance.addListener('beforeInsertVideo',function(t: Dict<any>, arg: Dict<any>){
        _this.list = arg[0]
        _this.getChild()

    });
    //视频
    editorInstance.addListener('afterUpVideo',function(t: Dict<any>, arg: Dict<any>) {
				console.log(arg);
		});
    //音频
    editorInstance.addListener('afterUpAudio',function(t: Dict<any>, arg: Dict<any>) {
				console.log(arg);
		});
    //文件
    editorInstance.addListener('beforeInsertFile',function(t: Dict<any>, arg: Dict<any>) {
				console.log(arg);
		});

    console.log(`编辑器实例${editorInstance.key}: `, editorInstance)
  }
  @Emit('getChild')
  public getChild(): any {
    return this.list
  }
  //上传图片
  public upImage(): void {
    (this.d as any) = this.myEditorImage.getDialog("insertimage");
    (this.d as any).render();
    (this.d as any).open()
  }
  //上传文件
  public upFile(): void {
    (this.d as any) = this.myEditorImage.getDialog("attachment");
    (this.d as any).render();
    (this.d as any).open();
  }
  //上传视频
  public upVideo(): void {
    (this.d as any) = this.myEditorImage.getDialog("insertvideo");
    (this.d as any).render();
    (this.d as any).open();
  }
  //上传音频
  public upAudio(): void {
    (this.d as any) = this.myEditorImage.getDialog("insertaudio");
    (this.d as any).render();
    (this.d as any).open();
  }
  /**
   * -END- CALL SERVICE
   */
}
