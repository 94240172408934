import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class SurveyService {
  @Inject(HttpService) private http!: HttpService;

 //下载
  public downLoadAll(param: Dict<any>,filename:any): Promise<any> {
    return this.http.downloadFile('/wecom-bsp-backend/admin/pcImport/exportTemplate', param, filename);
  }

  //上传
  public uploadBackend(param: Dict<any>, type: string): Promise<any> {
    let config: any = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    let queryString: any = {
      type:type
    };
    return this.http.post(
      '/wecom-bsp-backend/admin/pcImport/importExcel',
      param,
      queryString,
      config
    );
  }


  //france下拉框
  public franchise(): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcDropDownList/franchise');
  }
  //导出日志列表
  public importLogList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcImport/importLogList',param);
  }
  //查看失败原因
  public importLogById(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcImport/importLogById',param);
  }

  //公告提示新增
  public addAdvertisement(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/advertisement/addAdvertisement',param);
  }
  //公告提示修改
  public updateAdvertisement(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/advertisement/updateAdvertisement',param);
  }

  //类型名称下拉框
  public dropDown(): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/surveyType/dropDownSurveyType');
  }

  //人员范围下拉框
  public personnelScope(): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/surveyType/selectPersonnelScope');
  }

  //新增问卷类型-人员范围下拉
  public userGroupingList(): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/surveyType/userGroupingList');
  }

  //修改 问卷类型列表
  public listSurveyType(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/surveyType/listSurveyType',param);
  }
  //问卷列表
  public surveyList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/servey/listSurveyQuestionnaire',param);
  }



  //删除问卷类型
  public deleteSurveyType(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/surveyType/deleteSurveyType',param);
  }

  //新增问卷类型
  public addSurveyType(param: Dict<any>): Promise<any> {
    const blob =  this.http.post('/wecom-survey-api/admin/surveyType/addSurveyType',param);
    return blob;
  }

  //修改问卷类型
  public updateSurveyType(param: Dict<any>): Promise<any> {
    const blob =  this.http.post('/wecom-survey-api/admin/surveyType/updateSurveyType',param);
    return blob;
  }

  //点击选择文件
  public clickSelectFile(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-survey-api/admin/servey/upload',param);
  }
  //用户可见范围
  public getSelectUserList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/servey/selectUserPost',param);
  }
  //用户组可见范围
  public getSelectGroupList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/servey/selectUserGrouping',param);
  }



  public applicationsList(): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/surveyType/dropDownSurveyType');
  }
  //发布
  public updatePublishStatus(param: Dict<any>): Promise<any> {
    const blob =  this.http.post('/wecom-survey-api/admin/servey/updatePublishStatus', param);
    return blob;
  }

  public articleGet(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/servey/getSurveyQuestionnaire',param);
  }
  //导入成功之后的下载接口
  public updateExcel(param: Dict<any>,filename:any): Promise<any> {
    const blob =  this.http.downloadFile('/wecom-survey-api/admin/servey/updateExcel',param,filename);
    return blob;
  }




  public exportReport(param: Dict<any>,filename:any): Promise<any> {
    return this.http.downloadFile('/wecom-bsp-backend/admin/reportFrom/exportReport', param, filename);
  }


  //线索导出
  public impSurveyQuestionnaire(param: Dict<any>,filename:any): Promise<any> {
    return this.http.downloadFile('/wecom-survey-api/admin/servey/impSurveyQuestionnaire', param, filename);
  }
  //更新图文
  public articleUpdate(param: Dict<any>): Promise<any> {
   const blob = this.http.post('/wecom-survey-api/admin/servey/updateSurveyQuestionnaire', param);
    return blob;
  }
  //添加图文
  public articleAdd(param: Dict<any>): Promise<any> {
    const blob = this.http.post('/wecom-survey-api/admin/servey/addSurveyQuestionnaire', param,);
    return blob
  }
  //删除问卷
  public articleDelete(param: Dict<any>): Promise<any> {
     const blob = this.http.get('/wecom-survey-api/admin/servey/deleteSurveyQuestionnaire',param);
    return blob
  }


  //修改的时候删除单个文件
  public deleteSurveyFiles(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-survey-api/admin/servey/deleteSurveyFiles', param);
  }

  //维护明细
  public detailLog(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-survey-api/admin/servey/detailLog', param);
  }
  //批量发布或下架
  public updatePublishStatusList(param: Dict<any>): Promise<any> {
    const blob = this.http.post('/wecom-survey-api/admin/servey/updatePublishStatusList',param);
    return blob
  }
  public surveySpmHospitalList(param: Dict<any>): Promise<any> {
    const blob = this.http.get('/wecom-survey-api/admin/surveyType/surveySpmHospitalListPc',param);
    return blob
  }
  public surveySpmDoctorList(param: Dict<any>): Promise<any> {
    const blob = this.http.get('/wecom-survey-api/admin/surveyType/surveySpmDoctorListPc',param);
    return blob
  }



}
